import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import classes from "./NavigationBarNavList.module.css";
import "./NavigationBarNavList.css";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";

export default function NavigationBarNavList() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [isAuthenticated, setIsAuthenticated] = useState(
    () => JSON.parse(localStorage.getItem("isLoggedIn")) || false
  );

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Row className="gx-md-5">
          <Col xs="auto" className={classes.navLinkContainer}>
            <NavLink
              end
              style={{ textDecoration: "none" }}
              to={`/`}
              className={({ isActive }) =>
                isActive ? `${classes.activeTab}` : ""
              }
            >
              <p className={classes.navLinkText}>home</p>
            </NavLink>
          </Col>
          <Col xs="auto" className={classes.navLinkContainer}>
            <NavLink
              end
              style={{ textDecoration: "none" }}
              to={`/events`}
              className={({ isActive }) =>
                isActive ? `${classes.activeTab}` : ""
              }
            >
              <p className={classes.navLinkText}>all events</p>
            </NavLink>
          </Col>
        </Row>
        <IconButton
          onClick={handleClick}
          className={classes.accountIconContainer}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <span className={`material-symbols-outlined ${classes.accountIcon}`}>
            account_circle
          </span>
        </IconButton>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {isAuthenticated ? (
          <div>
            {/* <MenuItem className={classes.menuItem} onClick={handleClose}>
              <Avatar /> Profile
            </MenuItem>
            <MenuItem className={classes.menuItem} onClick={handleClose}>
              <Avatar /> My Events
            </MenuItem> */}
            {/* <Divider className={classes.menuDivider} /> */}
            <MenuItem className={classes.menuItem} onClick={handleClose}>
              {/* <ListItemIcon><Logout fontSize="small" /></ListItemIcon> */}
              Logout
            </MenuItem>
          </div>
        ) : (
          <div>
            <MenuItem className={classes.menuItem} onClick={handleClose}>
              Login <span className={classes.comingSoonTag}>coming soon</span>
            </MenuItem>
            <Divider className={classes.menuDivider} />
            <MenuItem className={classes.menuItem} onClick={handleClose}>
              Sign Up <span className={classes.comingSoonTag}>coming soon</span>
            </MenuItem>
          </div>
        )}
      </Menu>
    </>
  );
}
