import { Container, Row, Col } from "react-bootstrap";
import classes from "./Footer.module.css";
import { Link, NavLink } from "react-router-dom";
import { contactContext } from "../App";
import { useContext } from "react";
import businessLogo from "../images/eg-logo-lite.png";

const Footer = () => {
  const { contactModalShow, setContactModalShow, setContactUsDefaultMessage } =
    useContext(contactContext);

  const showContactModal = () => {
    setContactUsDefaultMessage("");
    setContactModalShow(true);
  };

  return (
    <section className={classes.footerContainer}>
      <Container className="customContainer">
        <Row>
          <Col xs={12}>
            <div className={classes.footerDivider}></div>
          </Col>
        </Row>
        <Row className="justify-content-xl-between">
          <Col className="d-flex flex-column justify-content-start">
            <div className="d-flex justify-content-md-start justify-content-center">
              <NavLink to="/" className={classes.navLink}>
                <img
                  src={businessLogo}
                  alt="business logo"
                  className={classes.businessLogo}
                />
              </NavLink>
            </div>
          </Col>
          <Col xs="12" xl="7" className={classes.footerNavLinkContainerParent}>
            <Row>
              <Col xs="12" md="4" className={classes.navLinkSectionContainer}>
                <div className={classes.sectionTitleContainer}>
                  <h4 className={classes.footerSectionTitle}>quick links</h4>
                </div>
                <div className={`${classes.navLinkContainer}`}>
                  <Link to="/" className={classes.navLink}>
                    <p className={`${classes.footerNavLink}`}>home</p>
                  </Link>
                </div>
                <div className={`${classes.navLinkContainer}`}>
                  <Link to="/events" className={classes.navLink}>
                    <p className={`${classes.footerNavLink}`}>all events</p>
                  </Link>
                </div>
                <div
                  className={`${classes.navLinkContainer}`}
                  style={{ margin: "0" }}
                >
                  <p
                    className={`${classes.footerNavLink}`}
                    onClick={showContactModal}
                  >
                    contact us
                  </p>
                </div>
              </Col>
              <Col xs="12" md="4" className={classes.navLinkSectionContainer}>
                <div className={classes.sectionTitleContainer}>
                  <h4 className={classes.footerSectionTitle}>Policies</h4>
                </div>
                <div className={`${classes.navLinkContainer}`}>
                  <Link to="/privacy-policy" className={classes.navLink}>
                    <p className={`${classes.footerNavLink}`}>privacy policy</p>
                  </Link>
                </div>
                <div className={`${classes.navLinkContainer}`}>
                  <Link to="/terms-and-conditions" className={classes.navLink}>
                    <p className={`${classes.footerNavLink}`}>
                      terms and conditions
                    </p>
                  </Link>
                </div>
                <div
                  className={`${classes.navLinkContainer}`}
                  style={{ margin: "0" }}
                >
                  <Link to="/refund-policy" className={classes.navLink}>
                    <p className={`${classes.footerNavLink}`}>refund policy</p>
                  </Link>
                </div>
              </Col>
              <Col xs="12" md="4" className={classes.navLinkSectionContainer}>
                <div className={classes.sectionTitleContainer}>
                  <h4 className={classes.footerSectionTitle}>contact</h4>
                </div>
                <div
                  className={`${classes.navLinkContainer} ${classes.contactInfoContainer}`}
                >
                  <span
                    className={`material-symbols-outlined ${classes.contactIcon}`}
                  >
                    call
                  </span>
                  <a
                    href="tel:+94768861493"
                    className="text-decoration-none cursor-pointer"
                  >
                    <p
                      className={`${classes.footerNavLink} ${classes.contactInfo}`}
                    >
                      +9476 88 61 493
                    </p>
                  </a>
                </div>
                <div
                  className={`${classes.navLinkContainer} ${classes.contactInfoContainer}`}
                >
                  <span
                    className={`material-symbols-outlined ${classes.contactIcon}`}
                  >
                    mail
                  </span>
                  <a
                    href="mailto:hello@eventgooze.com"
                    style={{ textDecoration: "none" }}
                  >
                    <p
                      className={`${classes.footerNavLink} ${classes.contactInfo}`}
                      style={{ cursor: "pointer" }}
                    >
                      hello@eventgooze.com
                    </p>
                  </a>
                </div>
                {/* this address div is only visible in screens greater than md */}
                {/* temporarily hidding the address div as we do not have a physical location yet */}
                <div
                  className={`d-md-none d-none ${classes.navLinkContainer} ${classes.contactInfoContainer}`}
                  style={{ margin: "0" }}
                >
                  <span
                    className={`material-symbols-outlined ${classes.contactIcon}`}
                  >
                    home_pin
                  </span>
                  <p
                    className={`${classes.footerNavLink} ${classes.contactInfo}`}
                  >
                    211/B/12, Gunasekara Estate, Alothiyawa, Welmilla junction,
                    Bandaragama, Sri Lanka
                  </p>
                </div>
                {/* this address div is only visible in screens smaller than md */}
                {/* temporarily hidding the address div as we do not have a physical location yet */}
                <div
                  className={`d-md-none d-none ${classes.navLinkContainer} ${classes.contactInfoContainer}`}
                  style={{ margin: "0" }}
                >
                  <p
                    className={`${classes.footerNavLink} ${classes.contactInfo}`}
                  >
                    <span
                      className={`material-symbols-outlined ${classes.contactIcon}`}
                    >
                      home_pin
                    </span>
                    211/B/12, Gunasekara Estate, Alothiyawa, Welmilla junction,
                    Bandaragama, Sri Lanka
                  </p>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <div className={classes.footerBottomDivider}></div>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <p className={classes.copyrightText}>
              Copyright <span className={classes.copyrightSymbol}>&copy;</span>{" "}
              2025 Leadgooze Technologies. All rights reserved.
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Footer;
