import { useEffect, useState } from "react";
import classes from "./CheckInTicketPackageDetails.module.css";
import CheckInTicketCountComponent from "./CheckInTicketCountComponent";
import { clear } from "@testing-library/user-event/dist/clear";

const CheckInTicketPackageDetails = ({
  packageName,
  seatsAvailable,
  packageId,
  setTotal,
  clearCheckIn,
}) => {
  const [ticketCount, setTicketCount] = useState(0);

  useEffect(() => {
    setTicketCount(0);
  }, [clearCheckIn]);

  return (
    <div className={classes.ticketDetailsContainer}>
      <div
        className={`d-flex justify-content-between ${classes.packageTitleContainer}`}
      >
        <div className="d-flex">
          <h6 className={classes.packageTitle}>{packageName}</h6>
          <span className={classes.availableSeats}>
            ({seatsAvailable} seats available)
          </span>
        </div>
        <CheckInTicketCountComponent
          ticketCount={ticketCount}
          setTicketCount={setTicketCount}
          seatsAvailable={seatsAvailable}
          packageId={packageId}
          setTotal={setTotal}
          ticketCountStyle={classes.ticketCountStyle}
        />
      </div>
      <div className={classes.packageDetailsDivider}></div>
    </div>
  );
};

export default CheckInTicketPackageDetails;
