import { useNavigate } from "react-router-dom";
import classes from "./NavbarBusinessLogo.module.css";
import siteLogo from "../images/eg-navbar-logo.png";

const NavbarBusinessLogo = ({ className }) => {
  const navigate = useNavigate();

  return (
    <div className="d-flex align-items-center">
      <img
        src={siteLogo}
        alt="site logo"
        className={`${classes.businessLogo} ${className}`}
        onClick={() => navigate("/")}
      />
      <h1 className={classes.eventgoozeName} onClick={() => navigate("/")}>
        eventgooze
      </h1>
    </div>
  );
};

export default NavbarBusinessLogo;
