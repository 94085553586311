import { Col } from "react-bootstrap";
import classes from "./AllEventsSingleEventComponent.module.css";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import PrimaryActionButton from "./PrimaryActionButton";

const AllEventsSingleEventComponent = ({
  eventId,
  eventThumbnail,
  eventTitle,
  eventSlug,
  eventStartDate,
  eventLocation,
  ticketPackages,
}) => {
  const navigate = useNavigate();
  const [date, setDate] = useState(null);
  const [month, setMonth] = useState(null);
  const [day, setDay] = useState(null);
  const [minPrice, setMinPrice] = useState(null);

  useEffect(() => {
    if (eventStartDate) {
      const date = new Date(eventStartDate);
      const day = String(date.getDate()).padStart(2, "0");
      const monthIndex = date.getMonth();
      const year = date.getFullYear();

      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      const monthName = monthNames[monthIndex];

      const formattedDate = `${day}-${month}-${year}`;
      setDate(formattedDate);
      setMonth(monthName);
      setDay(day);
    }
  }, [eventStartDate]);

  useEffect(() => {
    if (ticketPackages && ticketPackages.length > 0) {
      const minPrice = ticketPackages.reduce((min, packageItem) => {
        const price = parseFloat(packageItem.price);
        return price < min ? price : min;
      }, parseFloat(ticketPackages[0].price));

      setMinPrice(minPrice);
    }
  }, [ticketPackages]);

  return (
    <div className={classes.singleEventContainer}>
      <div>
        <div className={classes.eventDateContainer}>
          <p className={classes.eventMonth}>{month}</p>
          <p className={classes.eventDay}>{day}</p>
        </div>
        <div
          className={classes.eventThumbnailContainer}
          style={{ backgroundImage: `url(${eventThumbnail})` }}
        ></div>
        <div className={classes.eventDetailsContainer}>
          <h6 className={`${classes.eventName} d-sm-block d-none`}>
            {eventTitle.length > 45
              ? eventTitle.slice(0, 45) + "..."
              : eventTitle}
          </h6>
          <h6 className={`${classes.eventName} d-sm-none d-block`}>
            {eventTitle.length > 30
              ? eventTitle.slice(0, 30) + "..."
              : eventTitle}
          </h6>
          <p className={classes.eventLocation}>
            {" "}
            {eventLocation.length > 25
              ? eventLocation.slice(0, 25) + "..."
              : eventLocation}
          </p>
          {minPrice && (
            <p className={classes.eventPriceTag}>LKR {minPrice} Upwards</p>
          )}
        </div>
      </div>
      <div className={classes.bookNowButtonContainer}>
        <PrimaryActionButton
          className={classes.bookNowButton}
          buttonText={"Book Now"}
          onClick={() => navigate(`/events/${eventId}/${eventSlug}`)}
        />
        <div className={classes.buttonHalfCircle}></div>
        <div className={classes.buttonDashedLine}></div>
      </div>
    </div>
  );
};

export default AllEventsSingleEventComponent;
