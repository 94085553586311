import { Container, Row, Col } from "react-bootstrap";
import classes from "./NavigationBar.module.css";
import NavbarBusinessLogo from "./NavbarBusinessLogo";
import NavigationBarNavList from "./NavigationBarNavList";

const NavigationBar = () => {
  return (
    <section className={classes.navBarContainer}>
      <Container className="customContainer">
        <Row className="justify-content-between">
          <Col xs="auto" className="d-flex justify-content-center">
            <NavbarBusinessLogo />
          </Col>
          <Col xs="auto" className="d-flex align-items-center">
            <Row>
              <Col xs="auto">
                <div className={classes.navBarLinkContainerParent}>
                  <NavigationBarNavList />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default NavigationBar;
